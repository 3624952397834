// @flow

import {
  FETCH_COINS_BEGIN,
  FETCH_COINS_FAIL,
  FETCH_COINS_SUCCESS,
  FETCH_BLOCKS_BEGIN,
  FETCH_BLOCKS_SUCCESS,
  FETCH_BLOCKS_FAIL,
  FETCH_TXS_BEGIN,
  FETCH_TXS_SUCCESS,
  FETCH_TXS_FAIL,
  FETCH_MINMAX_BEGIN,
  FETCH_MINMAX_SUCCESS,
  FETCH_MINMAX_FAIL,
  FETCH_ATHL_BEGIN,
  FETCH_ATHL_SUCCESS,
  FETCH_ATHL_FAIL
} from './constants';

export type State = {
  fetching: number,
  lastUpdate: number,
  byId: {
    [currency_id: string]: {
      id: string,
      coinName: string,
      fullName: string,
      algorithm: string,
      proofType: string,
      totalCoinSupply: string
    }
  },
  athl: {
    [currency_id: string]: []
  },
  blocks: {
    [currency_id: string]: []
  },
  minmax: {
    [currency_id: string]: {}
  },
  txs: {
    [currency_id: string]: []
  },
  +allIds: string[]
};

export const initialState: State = {
  byId: {},
  athl: {},
  blocks: {},
  minmax: {},
  txs: {},
  allIds: [],
  fetching: 0, // 0 default, 1 = fetching, 2 = fetched
  lastUpdate: 0
};

// logic
const fetchCoinsBegin = (state): State => {
  return { ...state, fetching: 1 };
};
const fetchCoinsFail = (state): State => {
  return { ...state, fetching: 0 };
};

const fetchBlocksBegin = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 1 };
};
const fetchBlocksFail = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 0 };
};

const fetchTxsBegin = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 1 };
};
const fetchTxsFail = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 0 };
};

const fetchMinmaxBegin = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 1 };
};
const fetchMinmaxFail = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 0 };
};

const fetchAthlBegin = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 1 };
};
const fetchAthlFail = (state, payload: { symbol: string }): State => {
  return { ...state, fetching: 0 };
};

const fetchBlocksSuccess = (state, payload): State => {
  const { symbol, data } = payload;
  return {
    ...state,
    blocks: {
      [symbol]: data.blocks,
      ...state.blocks
    }
  };
};

const fetchTxsSuccess = (state, payload): State => {
  const { symbol, data } = payload;
  return {
    ...state,
    txs: {
      [symbol]: data.txs,
      ...state.txs
    }
  };
};

const fetchMinmaxSuccess = (state, payload): State => {
  const { symbol, data } = payload;
  return {
    ...state,
    minmax: {
      [symbol]: {
        min: data.data.min,
        max: data.data.max
      },
      ...state.minmax
    }
  };
};

const fetchAthlSuccess = (state, payload): State => {
  const { symbol, data } = payload;
  return {
    ...state,
    athl: {
      [symbol]: data
    }
  };
};

const fetchCoinsSuccess = (state, data): State => {
  const asArray = Object.entries(data);
  const allIds = asArray.map(entry => entry[0]);

  // Add the api URI to  the imageURL, then we don't need to reference the api in templates
  const formattedData = asArray.reduce((prev, curr) => {
    const [key, val] = curr; // val: CoinMetaResponse

    const formattedVal = {
      symbol: val.symbol, // $FlowFixMe: suppressing until I can annotate better
      coinName: val.coinName, // $FlowFixMe: suppressing until I can annotate better
      fullName: val.fullName, // $FlowFixMe: suppressing until I can annotate better
      algorithm: val.algorithm, // $FlowFixMe: suppressing until I can annotate better
      proofType: val.proofType, // $FlowFixMe: suppressing until I can annotate better
      totalCoinSupply: val.totalCoinSupply
    };
    return { ...prev, [key]: formattedVal };
  }, {});
  return {
    ...state,
    lastUpdate: +new Date(),
    fetching: 2,
    allIds,
    byId: formattedData
  };
};

// reducer
const currencies = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_COINS_BEGIN:
      return fetchCoinsBegin(state);
    case FETCH_COINS_SUCCESS:
      return fetchCoinsSuccess(state, action.payload);
    case FETCH_COINS_FAIL:
      return fetchCoinsFail(state);
    case FETCH_BLOCKS_BEGIN:
      return fetchBlocksBegin(state, action.payload);
    case FETCH_BLOCKS_SUCCESS:
      return fetchBlocksSuccess(state, action.payload);
    case FETCH_BLOCKS_FAIL:
      return fetchBlocksFail(state, action.payload);
    case FETCH_TXS_BEGIN:
      return fetchTxsBegin(state, action.payload);
    case FETCH_TXS_SUCCESS:
      return fetchTxsSuccess(state, action.payload);
    case FETCH_TXS_FAIL:
      return fetchTxsFail(state, action.payload);
    case FETCH_MINMAX_BEGIN:
      return fetchMinmaxBegin(state, action.payload);
    case FETCH_MINMAX_SUCCESS:
      return fetchMinmaxSuccess(state, action.payload);
    case FETCH_MINMAX_FAIL:
      return fetchMinmaxFail(state, action.payload);
    case FETCH_ATHL_BEGIN:
      return fetchAthlBegin(state, action.payload);
    case FETCH_ATHL_SUCCESS:
      return fetchAthlSuccess(state, action.payload);
    case FETCH_ATHL_FAIL:
      return fetchAthlFail(state, action.payload);
    default:
      return state;
  }
};

export default currencies;
