// @flow

import { TOGGLE_FAVORITE } from './constants';
import { type FavoriteAction } from './actions';

export type State = string[];

export const initialState: State = ['BCH'];

// logic
const toggleFavorite = (state: State, payload: { symbol: string }): State => {
  const { symbol } = payload;
  if (state.includes(symbol)) {
    return state.filter(fav => fav !== symbol);
  }
  return [...state, symbol];
};

// reducer
const favorites = (
  state: State = initialState,
  action: FavoriteAction
): State => {
  switch (action.type) {
    case TOGGLE_FAVORITE:
      return toggleFavorite(state, action.payload);
    default:
      return state;
  }
};

export default favorites;
