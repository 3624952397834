// @flow

import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { media } from 'ui/styles/utils';

// Styles which other components may want to use
export const fontBase = css`
  font-size: ${props => (props.small ? '12px' : props.large ? '16px' : '14px')};
  ${media.large`
    font-size: ${props =>
      props.small ? '13px' : props.large ? '19px' : '16px'};
  `};
`;

const Text = styled.p`
  ${fontBase};
  font-weight: ${props => (props.bold ? 700 : 400)};

  color: ${props => props.theme.foreground};
  ${props =>
    props.inverse &&
    css`
      color: ${props.theme.background};
    `}
  ${props =>
    props.primary &&
    css`
      color: ${props.theme.primary};
    `}
  ${props =>
    props.negative &&
    css`
      color: ${props.theme.negative};
    `}
  ${props =>
    props.positive &&
    css`
      color: ${props.theme.positive};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  margin: 0;

  ${props =>
    props.muted &&
    css`
      color: ${props =>
        chroma(props.theme.palette.text.default)
          .alpha(0.6)
          .css()};
    `}

  ${props =>
    props.size &&
    css`
      font-size: ${props.size};
    `}

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
  ${props =>
    props.left &&
    css`
      text-align: left;
    `}
`;

Text.displayName = 'Text';

export default Text;
