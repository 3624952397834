// @flow

import ReactGA from 'react-ga';
import * as amplitude from '@amplitude/analytics-browser';
import { ampli } from '@bitcoin-portal/web-ampli';

// Todo - Use a SP specific ID, maybe
// ReactGA.initialize('UA-59964190-1');

const options = {};

const trackPage = page => {
  ReactGA.set({
    page,
    ...options
  });
  ReactGA.pageview(page);
};

let currentPage = '';

export const trackEvent = (category, action, label) => {
  window.dataLayer.push({
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    event: 'eventBus'
  });
};

export const googleAnalyticsMiddleware = (store: any) => (next: Function) => (
  action: Function
) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.pathname}${action.payload.search}`;

    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }
  return next(action);
};

export const GAEvent = (
  category: 'graphView' | 'listView' | 'user',
  action: string,
  label?: ?string,
  value?: number
) => {
  // console.log(category, action, label, value)
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export const initAmplitude = async () => {
  try {
    if (typeof window === 'undefined') return;
    const urlParams = new URLSearchParams(window?.location.search);

    const sessionId = urlParams.get('sessionId');

    const deviceId = urlParams.get('deviceId');

    const config = {};

    if (sessionId) {
      config.sessionId = Number(sessionId);
    }
    if (deviceId) {
      config.deviceId = deviceId;
    }
    await amplitude.init(process.env.AMPLITUDE_KEY, undefined, config).promise;

    ampli.load({
      client: {
        apiKey: process.env.AMPLITUDE_KEY || ''
      }
    });
  } catch (e) {
    console.error('Error initialising Amplitude', e);
  }
};

export const sendAmpEvent = async ({ label, properties = {} }, cb = null) => {
  try {
    await amplitude.track(label, properties).promise;
    if (cb !== null) cb();
  } catch (e) {
    console.error('Error sending Amplitude event', e);
  }
};

export const sendAmpUserProps = (properties = {}) => {
  try {
    Object.keys(properties).forEach(k => {
      const identifyEvent = new amplitude.Identify();
      const value = properties[k];
      const stringifiedValue = JSON.stringify(value);
      identifyEvent.set(k, stringifiedValue);
      amplitude.identify(identifyEvent);
    });
  } catch (e) {
    console.error('Error identifying Amplitude user', e);
  }
};
