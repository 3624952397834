// @flow

import {
  FETCH_DAILY_BATCH,
  FETCH_DAILY_BATCH_SUCCESS,
  FETCH_DAILY_BATCH_FAIL,
  FETCH_DAILY_5Y,
  FETCH_DAILY_5Y_SUCCESS,
  FETCH_DAILY_5Y_FAIL
} from './constants';

export type DailyPriceShape = {
  time: number,
  open: number,
  close: number,
  high: number,
  low: number,
  value: number
};

export type State = {
  byId: {
    [currency_symbol: string]: {
      fetching: boolean,
      lastUpdate: number,
      history: DailyPriceShape[]
    }
  }
};

export const initialState: State = {
  byId: {}
};

const fetchDailyBatchBegin = (state: State, payload: { symbols: string[] }) => {
  const symbols = payload.symbols;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          fetching: true
        }
      }
    };
  }, state);
  // return state;
};
const fetchDailyBatchSuccess = (
  state: State,
  payload: { data: { [symbol: string]: DailyPriceShape[] }, symbols: string[] }
) => {
  const data = payload.data;
  const symbols = payload.symbols;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          ...data[curr],
          fetching: false,
          lastUpdate: +new Date()
        }
      }
    };
  }, state);
};
const fetchDailyBatchFail = (state: State, payload: { symbols: string[] }) => {
  const symbols = payload.symbols;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          fetching: false
        }
      }
    };
  }, state);
};

const fetchDaily5YBegin = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: true
        }
      }
    }
  };
};

const fetchDaily5YFail = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: false
        }
      }
    }
  };
};

const fetchDaily5YSuccess = (
  state: State,
  payload: {
    data: DailyPriceShape[],
    symbol: string,
    curr: string
  }
): State => {
  const data = payload.data.reverse();
  const symbol = payload.symbol;
  const curr = payload.curr;

  if (!data.length) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [symbol]: {
          ...state.byId[symbol],
          [curr]: {
            ...state.byId[symbol][curr],
            fetching: false
          }
        }
      }
    };
  }

  const cleaned = data
    .map(item => {
      return {
        time: item.time,
        open: item.open,
        close: item.close,
        high: item.high,
        low: item.low,
        value: item.close
      };
    })
    .filter(item => item.open);

  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          ...state.byId[symbol][curr],
          history: cleaned,
          fetching: false,
          lastUpdate: +new Date()
        }
      }
    }
  };
};

// reducer
const daily = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_DAILY_BATCH:
      return fetchDailyBatchBegin(state, action.payload);
    case FETCH_DAILY_BATCH_SUCCESS:
      return fetchDailyBatchSuccess(state, action.payload);
    case FETCH_DAILY_BATCH_FAIL:
      return fetchDailyBatchFail(state, action.payload);
    case FETCH_DAILY_5Y:
      return fetchDaily5YBegin(state, action.payload);
    case FETCH_DAILY_5Y_SUCCESS:
      return fetchDaily5YSuccess(state, action.payload);
    case FETCH_DAILY_5Y_FAIL:
      return fetchDaily5YFail(state, action.payload);
    default:
      return state;
  }
};

export default daily;
