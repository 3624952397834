import { createGlobalStyle } from 'styled-components';
import sfMonoRegularEOT from '../../assets/fonts/sf-mono/SFMono-Regular.eot';
import sfMonoRegularWOFF2 from '../../assets/fonts/sf-mono/SFMono-Regular.woff2';
import sfMonoRegularWOFF from '../../assets/fonts/sf-mono/SFMono-Regular.woff';
import sfMonoRegularTTF from '../../assets/fonts/sf-mono/SFMono-Regular.ttf';
import sfMonoRegularSVG from '../../assets/fonts/sf-mono/SFMono-Regular.svg';
import sfMonoBoldEOT from '../../assets/fonts/sf-mono/SFMono-Bold.eot';
import sfMonoBoldWOFF2 from '../../assets/fonts/sf-mono/SFMono-Bold.woff2';
import sfMonoBoldWOFF from '../../assets/fonts/sf-mono/SFMono-Bold.woff';
import sfMonoBoldTTF from '../../assets/fonts/sf-mono/SFMono-Bold.ttf';
import sfMonoBoldSVG from '../../assets/fonts/sf-mono/SFMono-Bold.svg';

export const AppStyle = createGlobalStyle`
  @font-face {
    font-family: "SF Mono";
    src: url("${sfMonoRegularEOT}");
    src: url("${sfMonoRegularEOT}?#iefix") format("embedded-opentype"),
      url("${sfMonoRegularWOFF2}") format("woff2"),
      url("${sfMonoRegularWOFF}}") format("woff"),
      url("${sfMonoRegularTTF}") format("ttf"),
      url("${sfMonoRegularSVG}#SF-Mono-Regular") format("svg");
    font-style: normal;
    font-weight: normal;
    font-display: fallback; /* <- this can be added to each @font-face definition */
  }

  @font-face {
    font-family: "SF Mono";
    src: url("${sfMonoBoldEOT}");
    src: url("${sfMonoBoldEOT}?#iefix") format("embedded-opentype"),
      url("${sfMonoBoldWOFF2}") format("woff2"),
      url("${sfMonoBoldWOFF}}") format("woff"),
      url("${sfMonoBoldTTF}") format("ttf"),
      url("${sfMonoBoldSVG}#SF-Mono-Bold") format("svg");
    font-style: normal;
    font-weight: 700;
    font-display: fallback; /* <- this can be added to each @font-face definition */
  }
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  ___gatsby,
  ___gatsby > div {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    color: #131720;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    @media screen and (min-width: 960px) {
      font-size: 20px;
    }
  }
`;
