// @flow

import {
  FETCH_TRADE_LINKS,
  FETCH_TRADE_LINKS_FAIL,
  FETCH_TRADE_LINKS_SUCCESS
} from './constants';

export type State = {
  isFetching: boolean,
  error: string,
  tradeLinks: Object
};

const initialState: State = {
  isFetching: false,
  error: null,
  tradeLinks: null
};

const fetchTradeLinksBegin = (state: State) => {
  return {
    ...state,
    isFetching: true,
    error: null
  };
};

const fetchTradeLinksSuccess = (state: State, tradeLinks: Object) => {
  return {
    ...state,
    isFetching: false,
    error: null,
    tradeLinks
  };
};

const fetchTradeLinksFail = (state: State, error: string) => {
  return {
    ...state,
    isFetching: false,
    error,
    tradeLinks: {}
  };
};

const verse = (state: State = initialState, action: Object) => {
  switch (action.type) {
    case FETCH_TRADE_LINKS:
      return fetchTradeLinksBegin(state);
    case FETCH_TRADE_LINKS_SUCCESS:
      return fetchTradeLinksSuccess(state, action.payload);
    case FETCH_TRADE_LINKS_FAIL:
      return fetchTradeLinksFail(state, action.payload);
    default:
      return state;
  }
};

export default verse;
