// @flow

export const FETCH_NEWS_BEGIN = 'news::FETCH_NEWS_BEGIN';
export const FETCH_NEWS_SUCCESS = 'news::FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'news::FETCH_NEWS_FAIL';
export const FETCH_LATEST_NEWS_BEGIN = 'news::FETCH_LATEST_NEWS_BEGIN';
export const FETCH_LATEST_NEWS_SUCCESS = 'news::FETCH_LATEST_NEWS_SUCCESS';
export const FETCH_LATEST_NEWS_FAIL = 'news::FETCH_LATEST_NEWS_FAIL';
export const NEXT_NEWS_PAGE = 'news::NEXT_NEWS_PAGE';
export const SET_NEWS_PAGE = 'news::SET_NEWS_PAGE';
export const SET_NEWS = 'news::SET_NEWS';

export const FETCH_SINGLE = 'news::FETCH_SINGLE';

export const PAGE_SIZE = 4;
