// @flow

import styled from 'styled-components';
import { media } from 'ui/styles/utils';

const Spacer = styled.div`
  margin-top: 10px;
  ${media.medium`
    margin-top: 30px;
  `};
`;

export default Spacer;
