// @flow
import React from 'react';

export const FETCH_BITCOINS = 'prices::FETCH_BITCOINS';
export const FETCH_BITCOINS_SUCCESS = 'prices::FETCH_BITCOINS_SUCCESS';
export const FETCH_BITCOINS_FAIL = 'prices::FETCH_BITCOINS_FAIL';

export const FETCH_TICKER_ALL = 'prices::FETCH_TICKER_ALL';
export const FETCH_TICKER_ALL_SUCCESS = 'prices::FETCH_TICKER_ALL_SUCCESS';
export const FETCH_TICKER_ALL_FAIL = 'prices::FETCH_TICKER_ALL_FAIL';

export const FETCH_COIN_TYPE = 'prices::FETCH_COIN_TYPE';
export const FETCH_COIN_TYPE_SUCCESS = 'prices::FETCH_COIN_TYPE_SUCCESS';
export const FETCH_COIN_TYPE_FAIL = 'prices::FETCH_COIN_TYPE_FAIL';

export const GOTO_PAGE = 'prices::GOTO_PAGE';
export const RESET_PAGE = 'prices::RESET_PAGE';
export const PAGE_NEXT = 'prices::PAGE_NEXT';
export const PAGE_PREV = 'prices::PAGE_PREV';

export const SET_COIN_FILTER_TYPE = 'prices::SET_COIN_FILTER_TYPE';
export const SET_SORT_ORDER = 'prices::SET_SORT_ORDER';

export const PAGE_SIZE = 20;

export const BITCOINS = ['BCH', 'BTC', 'ETH'];
export const BUY_COINS = [
  'BAT',
  'BCH',
  'BNB',
  'BTC',
  'DOT',
  'ETH',
  'LTC',
  'XLM',
  'XRP',
  'AVAX',
  'MATIC'
];
export const CUSTOM_CREDIT_COINS = [
  'BTC',
  'BCH',
  'ETH',
  'BNB',
  'XRP',
  'LTC',
  'TRX',
  'XLM',
  'EOS'
];

export const DEFI_TOKENS = [
  'LEND',
  'DAI',
  'MKR',
  'WBTC',
  'YFI',
  'ZRX',
  'UNI',
  'SUSHI',
  'COMP',
  'VERSE'
];

export const GAMES_COINS = [
  'BTC',
  'BCH',
  'ETH',
  'XMR',
  'XLM',
  'USDT',
  'XAI',
  'COMP',
  'SRM',
  'YFI',
  'USDH',
  'USDC',
  'BSV',
  'DASH',
  'DOGE',
  'LTC',
  'ZEC',
  'SAI'
];
export const GENERAL_CREDIT_COINS = [
  'ADA',
  'DASH',
  'ETC',
  'MIOTA',
  'KIN',
  'XMR',
  'OMG',
  'QTUM',
  'VET',
  'ZEC',
  'ONT',
  'XTZ'
];
export const EARN_CRED_COINS = [
  'BTC',
  'ETH',
  'BCH',
  'XRP',
  'USDT',
  'BAT',
  'OMG',
  'TRX',
  'LTC',
  'TUSD',
  'LBA'
];

export const EXCHANGE_URL = 'https://exchange.bitcoin.com/markets/';
export const VERSE_URL = 'https://verse.bitcoin.com';

export const HOMEPAGE_EXCHANGE_COINS = {
  ACD: 'https://app.bitcoin.com',
  ADA:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  ATOM: 'https://app.bitcoin.com',
  BCH: `${EXCHANGE_URL}BCH-USD`,
  BTC: `${EXCHANGE_URL}BTC-USD`,
  BTG:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  BTS:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  DASH: `${EXCHANGE_URL}DASH-USD`,
  FLEX: `${EXCHANGE_URL}FLEX-USD`,
  FLEXUSD: `${EXCHANGE_URL}flexUSD-USD`,
  EOS:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  ETC:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  ETH: `${EXCHANGE_URL}ETH-USD`,
  GOC: `${EXCHANGE_URL}GOC-USD`,
  LINK: `${EXCHANGE_URL}LINK-USD`,
  LTC: `${EXCHANGE_URL}LTC-USD`,
  NEO:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  OMG: `${EXCHANGE_URL}OMG-USD`,
  ONT: 'https://app.bitcoin.com',
  QTUM:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  SAI: 'https://app.bitcoin.com',
  SPICE: 'https://app.bitcoin.com',
  STEEM:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  TRX:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  USDH: 'https://app.bitcoin.com',
  USDT: `${EXCHANGE_URL}USDT-USD`,
  XEM:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  XLM:
    'https://capital.com/trading/signup&utm_source=bitcoin.com&utm_campaign=regform',
  XMR: 'https://app.bitcoin.com',
  XRP: `${EXCHANGE_URL}XRP-USD`,
  XZC: 'https://app.bitcoin.com',
  ZEC: 'https://app.bitcoin.com'
};

export const EXCHANGE_COINS = {
  '1INCH': `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=1INCH`,
  AAVE: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=AAVE`,
  APE: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=APE`,
  AVAX: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=AVAX`,
  AXS: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=AXS`,
  BAT: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=BAT`,
  BCH: `${VERSE_URL}/swap/?provider=sideshift&to=BCH&tochain=BCH`,
  BTC: `${VERSE_URL}/swap/?provider=sideshift&to=BTC&tochain=BTC`,
  COMP: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=COMP`,
  DAI: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=DAI`,
  DASH: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=DASH`,
  DOGE: `${VERSE_URL}/swap/?provider=sideshift&to=BTC&tochain=DOGE`,
  ENS: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=ENS`,
  ETH: `${VERSE_URL}/swap/?provider=bitcoincom_eth&from=ETH`,
  HEX: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=HEX`,
  LDO: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=LDO`,
  LINK: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=LINK`,
  LRC: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=LRC`,
  LTC: `${VERSE_URL}/swap/?provider=sideshift&to=LTC&tochain=LTC`,
  MANA: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=MANA`,
  MATIC: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=MATIC&tochain=MATIC`,
  QNT: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=QNT`,
  SAND: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=SAND`,
  SNX: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=SNX`,
  SOL: `${VERSE_URL}/swap/?provider=sideshift&to=SOL&tochain=SOL`,
  SUSHI: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=SUSHI`,
  TANGO: `${VERSE_URL}/swap/?provider=bitcoincom&to=TANGO`,
  USDT: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=USDT`,
  USDC: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=USDC`,
  UNI: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=UNI`,
  VERSE: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=VERSE`,
  WBTC: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=WBTC`,
  WETH: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=WETH`,
  XAI: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=XAI`,
  YFI: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=YFI`,
  ZEC: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=ZEC`,
  WZANO: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=WZANO`,
  SHIB: `${VERSE_URL}/swap/?provider=bitcoincom_eth&to=SHIB`
};

export const WEB_WALLET_URL = 'https://app.bitcoin.com';

export const COMP_COINS = [
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/1.png"
        width="24"
        alt="BTC"
      />
    ),
    label: 'Bitcoin',
    value: 'BTC'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/1831.png"
        width="24"
        alt="BCH"
      />
    ),
    label: 'Bitcoin Cash',
    value: 'BCH'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/1027.png"
        width="24"
        alt="ETH"
      />
    ),
    label: 'Ethereum',
    value: 'ETH'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/2010.png"
        width="24"
        alt="ADA"
      />
    ),
    label: 'Cardano',
    value: 'ADA'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/1839.png"
        width="24"
        alt="BNB"
      />
    ),
    label: 'Binance Coin',
    value: 'BNB'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/4129.png"
        width="24"
        alt="DOT"
      />
    ),
    label: 'Polkadot',
    value: 'DOT'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/825.png"
        width="24"
        alt="USDT"
      />
    ),
    label: 'Tether',
    value: 'USDT'
  },
  {
    image: (
      <img
        src="https://markets.bitcoin.com/images/coins/52.png"
        width="24"
        alt="XRP"
      />
    ),
    label: 'XRP',
    value: 'XRP'
  }
];
