// @flow

import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createBrowserHistory, createMemoryHistory } from 'history';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  responsiveStoreEnhancer,
  createResponsiveStateReducer
} from 'redux-responsive';

import currencies from './currencies/reducer';
import prices from './prices/reducer';
import minutely from './minutely/reducer';
import hourly from './hourly/reducer';
import daily from './daily/reducer';
import exchanges from './exchanges/reducer';
import fiat from './fiat/reducer';
import meta from './meta/reducer';
import movers from './movers/reducer';
import news from './news/reducer';
import markets from './markets/reducer';
import quotes from './quotes/reducer';
import supply from './supply/reducer';
import tokens from './tokens/reducer';
import transactions from './transactions/reducer';
import favorites from './favorites/reducer';
import theme, { type State as StateTheme } from './theme/reducer';
import verse from './verse/reducer';

import { sizes } from 'ui/styles/utils';
// import { googleAnalyticsMiddleware } from 'utils/analytics';

import { type State as StateCurrencies } from './currencies/reducer';
import { type State as StateHourly } from './hourly/reducer';
import { type State as StatePrices } from './prices/reducer';
import { type State as StateDaily } from './daily/reducer';
import { type State as StateExchanges } from './exchanges/reducer';
import { type State as StateFiat } from './fiat/reducer';
import { type State as StateMeta } from './meta/reducer';
import { type State as StateMovers } from './movers/reducer';
import { type State as StateMinutely } from './minutely/reducer';
import { type State as StateFavorites } from './favorites/reducer';
import { type State as StateNews } from './news/reducer';
import { type State as StateMarkets } from './markets/reducer';
import { type State as StateQuotes } from './quotes/reducer';
import { type State as StateSupply } from './supply/reducer';
import { type State as StateTokens } from './tokens/reducer';
import { type State as StateTransactions } from './transactions/reducer';
import { type State as StateVerse } from './verse/reducer';

export type FullState = {
  currencies: StateCurrencies,
  prices: StatePrices,
  minutely: StateMinutely,
  daily: StateDaily,
  exchanges: StateExchanges,
  fiat: StateFiat,
  hourly: StateHourly,
  meta: StateMeta,
  movers: StateMovers,
  theme: StateTheme,
  favorites: StateFavorites,
  news: StateNews,
  markets: StateMarkets,
  quotes: StateQuotes,
  supply: StateSupply,
  tokens: StateTokens,
  transactions: StateTransactions,
  browser: Object,
  router: Object,
  verse: StateVerse
};

function logger({ getState }) {
  return next => action => {
    if (process.env.NODE_ENV === 'development') {
      console.log('DISPATCH ::', action.type);
    }
    return next(action);
  };
}

const hasWindow =
  typeof window === 'object' && window !== null && window.self === window;
export const history = hasWindow
  ? createBrowserHistory()
  : createMemoryHistory();

// window.REDUX_STATE is for Server Side Rendering
const initialState =
  hasWindow && window.REDUX_STATE !== '__SERVER_REDUX_STATE__'
    ? window.REDUX_STATE
    : {};
// eslint-disable-next-line
hasWindow ? delete window.REDUX_STATE : null;

const middleware = [
  thunk,
  routerMiddleware(history),
  // googleAnalyticsMiddleware,
  logger
];

const rootReducer = combineReducers({
  browser: createResponsiveStateReducer(sizes),
  router: connectRouter(history),
  currencies,
  prices,
  minutely,
  hourly,
  daily,
  exchanges,
  fiat,
  meta,
  movers,
  favorites,
  markets,
  quotes,
  supply,
  tokens,
  transactions,
  news,
  theme,
  verse
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['favorites', 'theme']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(responsiveStoreEnhancer, applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export const configureStore = (initialData: Object) => {
  return createStore(
    persistedReducer,
    initialData,
    composeWithDevTools(responsiveStoreEnhancer, applyMiddleware(...middleware))
  );
};

export { persistor };
export default store;
