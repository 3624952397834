// @flow

export const FETCH_COINS_BEGIN = 'currencies::FETCH_COINS_START';
export const FETCH_COINS_SUCCESS = 'currencies::FETCH_COINS_SUCCESS';
export const FETCH_COINS_FAIL = 'currencies::FETCH_COINS_FAIL';

export const FETCH_BLOCKS_BEGIN = 'currencies::FETCH_BLOCKS_BEGIN';
export const FETCH_BLOCKS_SUCCESS = 'currencies::FETCH_BLOCKS_SUCCESS';
export const FETCH_BLOCKS_FAIL = 'currencies::FETCH_BLOCKS_FAIL';

export const FETCH_TXS_BEGIN = 'currencies::FETCH_TXS_BEGIN';
export const FETCH_TXS_SUCCESS = 'currencies::FETCH_TXS_SUCCESS';
export const FETCH_TXS_FAIL = 'currencies::FETCH_TXS_FAIL';

export const FETCH_MINMAX_BEGIN = 'currencies::FETCH_MINMAX_BEGIN';
export const FETCH_MINMAX_SUCCESS = 'currencies::FETCH_MINMAX_SUCCESS';
export const FETCH_MINMAX_FAIL = 'currencies::FETCH_MINMAX_FAIL';

export const FETCH_ATHL_BEGIN = 'currencies::FETCH_ATHL_BEGIN';
export const FETCH_ATHL_SUCCESS = 'currencies::FETCH_ATHL_SUCCESS';
export const FETCH_ATHL_FAIL = 'currencies::FETCH_ATHL_FAIL';

export const FETCH_TOP = 'currencies::FETCH_TOP';
export const FETCH_SINGLE = 'currencies::FETCH_SINGLE';
