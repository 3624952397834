// @flow

import palette from './palette';
import chroma from 'chroma-js';

export const themeLight = {
  muted: palette.mineShaft.alpha(0.5).css(),
  primary: palette.seaFoam.css(),
  primaryMuted: palette.seaFoam.set('hsl.l', 0.96).css(),
  background: palette.bgLight.css(),
  foreground: palette.mineShaft.alpha(0.9).css(),
  positive: palette.seaFoam.css(),
  negative: palette.indianred.css(),
  faded: palette.bgLight.darken(0.1).css(),
  faded2: palette.bgLight
    .set('hsl.l', 0.99)
    .darken(0.8)
    .css(),
  chartColors: chroma.scale([chroma('#81c7b2'), chroma('#edfaf6')]).colors(10),
  areaFill: palette.forestgreen.brighten(0.8).css()
};

export const themeDark = {
  muted: palette.blackHaze.alpha(0.5).css(),
  primary: palette.seaFoam.css(),
  primaryMuted: palette.seaFoam.alpha(0.2).css(),
  background: palette.midnight.css(),
  foreground: palette.blackHaze.css(),
  positive: palette.seaFoam.css(),
  negative: palette.vermillion.css(),
  faded: chroma('#2d323b').css(),
  faded2: chroma('#353a44').css(),
  chartColors: chroma.scale([palette.seaFoam, palette.bgLight]).colors(10),
  areaFill: palette.forestgreen.brighten(0.5).css()
};
