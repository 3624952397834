// @flow

import {
  FETCH_HOURLY_BATCH,
  FETCH_HOURLY_BATCH_SUCCESS,
  FETCH_HOURLY_BATCH_FAIL,
  FETCH_HOURLY_MONTH,
  FETCH_HOURLY_MONTH_SUCCESS,
  FETCH_HOURLY_MONTH_FAIL
} from './constants';

export type HourlyPriceShape = {
  time: number,
  open: number,
  close: number,
  high: number,
  low: number,
  volume: number
};

export type State = {
  byId: {
    [currency_symbol: string]: {
      fetching: boolean,
      lastUpdate: number,
      history: HourlyPriceShape[],
      compression: number
    }
  }
};

export const initialState: State = {
  byId: {}
};

// logic

// Batch fetching reducers
const fetchHourlyBatchBegin = (
  state: State,
  payload: { symbols: string[] }
): State => {
  const symbols = payload.symbols;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          fetching: true
        }
      }
    };
  }, state);
};

const fetchHourlyBatchFail = (
  state: State,
  payload: { symbols: string[] }
): State => {
  const symbols = payload.symbols;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          fetching: false
        }
      }
    };
  }, state);
};

const fetchHourlyBatchSuccess = (
  state: State,
  payload: {
    data: {
      [curr: string]: {
        [symbol: string]: HourlyPriceShape[]
      }
    },
    symbols: string[],
    compression: number
  }
): State => {
  const data = payload.data;
  const symbols = payload.symbols;
  const compression = payload.compression;

  return symbols.reduce((prev, curr) => {
    return {
      ...prev,
      byId: {
        ...prev.byId,
        [curr]: {
          ...prev.byId[curr],
          ...data[curr],
          fetching: false,
          lastUpdate: +new Date(),
          compression
        }
      }
    };
  }, state);
};

// Monthly fetching reducers
const fetchHourlyMonthBegin = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: true
        }
      }
    }
  };
};

const fetchHourlyMonthFail = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: false
        }
      }
    }
  };
};

const fetchHourlyMonthSuccess = (
  state: State,
  payload: {
    data: HourlyPriceShape[],
    symbol: string,
    curr: string
  }
): State => {
  const data = payload.data.reverse();
  const symbol = payload.symbol;
  const curr = payload.curr;

  if (!data.length) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [symbol]: {
          ...state.byId[symbol],
          [curr]: {
            ...state.byId[symbol][curr],
            fetching: false
          }
        }
      }
    };
  }

  const cleaned = data
    .map(item => {
      return {
        time: item.time,
        open: item.open,
        close: item.close,
        high: item.high,
        value: item.close,
        low: item.low
      };
    })
    .filter(item => item.open);

  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          ...state.byId[symbol][curr],
          history: cleaned,
          fetching: false,
          lastUpdate: +new Date()
        }
      }
    }
  };
};

// reducer
const hourly = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_HOURLY_BATCH:
      return fetchHourlyBatchBegin(state, action.payload);
    case FETCH_HOURLY_BATCH_SUCCESS:
      return fetchHourlyBatchSuccess(state, action.payload);
    case FETCH_HOURLY_BATCH_FAIL:
      return fetchHourlyBatchFail(state, action.payload);
    case FETCH_HOURLY_MONTH:
      return fetchHourlyMonthBegin(state, action.payload);
    case FETCH_HOURLY_MONTH_SUCCESS:
      return fetchHourlyMonthSuccess(state, action.payload);
    case FETCH_HOURLY_MONTH_FAIL:
      return fetchHourlyMonthFail(state, action.payload);
    default:
      return state;
  }
};

export default hourly;
