// @flow

import React from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';

import loaderAnimation from './images/loader.json';

const Main = styled.div`
  padding: 32px;
  margin: 0px auto;
  transform: scale(${props => (props.size ? props.size : 2)});
  width: ${props => (props.width ? props.width : '80px')};
`;
Main.displayName = 'Main';

interface Props {
  size: number;
  width: string;
}

class LoaderDots extends React.Component<Props, State> {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById('loader-dots'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loaderAnimation
    });
  }

  render() {
    return (
      <Main
        size={this.props.size}
        width={this.props.width}
        id="loader-dots"
      ></Main>
    );
  }
}

LoaderDots.displayName = 'LoaderDots';

export default LoaderDots;
