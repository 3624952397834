// @flow

import _chunk from 'lodash/chunk';

import {
  FETCH_EXCHANGES,
  FETCH_EXCHANGES_SUCCESS,
  FETCH_EXCHANGES_FAIL,
  FETCH_EXCHANGE_HISTORY,
  FETCH_EXCHANGE_HISTORY_SUCCESS,
  FETCH_EXCHANGE_HISTORY_FAIL,
  FETCH_EXCHANGE_PAIRS,
  FETCH_EXCHANGE_PAIRS_SUCCESS,
  FETCH_EXCHANGE_PAIRS_FAIL,
  FETCH_EXCHANGE_PAIR_HISTORY,
  FETCH_EXCHANGE_PAIR_HISTORY_SUCCESS,
  FETCH_EXCHANGE_PAIR_HISTORY_FAIL,
  GOTO_PAGE,
  PAGE_NEXT,
  PAGE_SIZE,
  SET_SORT_ORDER
} from './constants';

export type ExchangeShape = {
  name: string,
  fiats: string[],
  pairs: number,
  volume: number
};

export type ExchangeHistoryShape = {
  time: number,
  volume: number
};

export type ExchangePairHistoryShape = {
  baseCcy: string,
  quoteCcy: string,
  volume: number,
  price: number
};

export type State = {
  currentPage: number,
  totalPages: number,
  fetching: boolean,
  exchanges: [],
  sort: {
    by: SortType,
    order: 'asc' | 'desc'
  },
  byId: {
    [exchange: string]: {
      fetching: boolean,
      pairs: ExchangePairHistoryShape[],
      history: ExchangeHistoryShape[],
      byQuote: {
        [quoteCurr: string]: {
          [baseCurr: string]: {
            fetching: boolean,
            history: []
          }
        }
      }
    }
  }
};

export const initialState: State = {
  currentPage: 0,
  totalPages: 0,
  fetching: false,
  sort: { by: 'rankedVolume', order: 'desc' },
  exchanges: [],
  byId: {}
};

const pageNext = (state: State): State => {
  if (state.totalPages === 0) return { ...state, currentPage: 0 };
  return {
    ...state,
    currentPage:
      state.totalPages > state.currentPage
        ? state.currentPage + 1
        : state.currentPage
  };
};

const setSortOrder = (state: State, sortBy: SortType): State => {
  const order =
    sortBy === state.sort.by
      ? state.sort.order === 'asc'
        ? 'desc'
        : 'asc'
      : 'desc';

  return {
    ...state,
    currentPage: 0,
    sort: {
      by: sortBy,
      order: order
    }
  };
};

const gotoPage = (state: State, payload: number): State => {
  const page = payload.page;
  return {
    ...state,
    currentPage: page
  };
};

const fetchExchangesBegin = (state: State) => {
  return {
    ...state,
    fetching: true
  };
};

const fetchExchangesFail = (state: State) => {
  return {
    ...state,
    fetching: false
  };
};

const fetchExchangesSuccess = (state: State, data: Object): State => {
  return {
    ...state,
    exchanges: [...data, ...state.exchanges]
  };
};

const fetchExchangeHistoryBegin = (state: State) => {
  return {
    ...state,
    fetching: true
  };
};

const fetchExchangeHistoryFail = (state: State) => {
  return {
    ...state,
    fetching: false
  };
};

const fetchExchangeHistorySuccess = (state: State, data: Object): State => {
  const exchange = data.exchange;
  const history = data.data.reverse();

  return {
    ...state,
    byId: {
      ...state.byId,
      [exchange]: {
        ...state.byId[exchange],
        history: history
      }
    }
  };
};

const fetchExchangePairsBegin = (state: State) => {
  return { ...state };
};

const fetchExchangePairsFail = (state: State) => {
  return { ...state };
};

const fetchExchangePairsSuccess = (
  state: State,
  payload: {
    data: Object,
    exchange: string
  }
): State => {
  const data = payload.data;
  const exchange = payload.exchange;
  const totalPages = _chunk(data.USD, PAGE_SIZE).length - 1;
  return {
    ...state,
    totalPages,
    byId: {
      ...state.byId,
      [exchange]: {
        ...state.byId[exchange],
        pairs: data.USD,
        fetching: false
      }
    }
  };
};

const fetchExchangePairHistoryBegin = (
  state: State,
  payload: {
    baseCurr: string,
    quoteCurr: string,
    exchange: string
  }
) => {
  const baseCurr = payload.baseCurr;
  const quoteCurr = payload.quoteCurr;
  const exchange = payload.exchange;
  return {
    ...state,
    byId: {
      ...state.byId,
      [exchange]: {
        ...state.byId[exchange],
        byQuote: {
          ...state.byId[exchange].byQuote,
          [quoteCurr]: {
            [baseCurr]: {
              fetching: true,
              history: []
            }
          }
        }
      }
    }
  };
};

const fetchExchangePairHistoryFail = (
  state: State,
  payload: {
    baseCurr: string,
    quoteCurr: string,
    exchange: string
  }
) => {
  const baseCurr = payload.baseCurr;
  const quoteCurr = payload.quoteCurr;
  const exchange = payload.exchange;
  return {
    ...state,
    byId: {
      ...state.byId,
      [exchange]: {
        ...state.byId[exchange],
        byQuote: {
          ...state.byId[exchange].byQuote,
          [quoteCurr]: {
            [baseCurr]: {
              fetching: false,
              history: []
            }
          }
        }
      }
    }
  };
};

const fetchExchangePairHistorySuccess = (
  state: State,
  payload: {
    data: Object,
    baseCurr: string,
    quoteCurr: string,
    exchange: string
  }
): State => {
  const data = payload.data;
  const baseCurr = payload.baseCurr;
  const quoteCurr = payload.quoteCurr;
  const exchange = payload.exchange;
  return {
    ...state,
    byId: {
      ...state.byId,
      [exchange]: {
        ...state.byId[exchange],
        byQuote: {
          ...state.byId[exchange].byQuote,
          [quoteCurr]: {
            ...state.byId[exchange].byQuote[quoteCurr],
            [baseCurr]: {
              fetching: false,
              history: data.history
            }
          }
        }
      }
    }
  };
};

const exchanges = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_EXCHANGES:
      return fetchExchangesBegin(state);
    case FETCH_EXCHANGES_SUCCESS:
      return fetchExchangesSuccess(state, action.payload);
    case FETCH_EXCHANGES_FAIL:
      return fetchExchangesFail(state);
    case FETCH_EXCHANGE_HISTORY:
      return fetchExchangeHistoryBegin(state);
    case FETCH_EXCHANGE_HISTORY_SUCCESS:
      return fetchExchangeHistorySuccess(state, action.payload);
    case FETCH_EXCHANGE_HISTORY_FAIL:
      return fetchExchangeHistoryFail(state);
    case FETCH_EXCHANGE_PAIRS:
      return fetchExchangePairsBegin(state);
    case FETCH_EXCHANGE_PAIRS_SUCCESS:
      return fetchExchangePairsSuccess(state, action.payload);
    case FETCH_EXCHANGE_PAIRS_FAIL:
      return fetchExchangePairsFail(state);
    case FETCH_EXCHANGE_PAIR_HISTORY:
      return fetchExchangePairHistoryBegin(state, action.payload);
    case FETCH_EXCHANGE_PAIR_HISTORY_FAIL:
      return fetchExchangePairHistoryFail(state, action.payload);
    case FETCH_EXCHANGE_PAIR_HISTORY_SUCCESS:
      return fetchExchangePairHistorySuccess(state, action.payload);
    case GOTO_PAGE:
      return gotoPage(state, action.payload);
    case PAGE_NEXT:
      return pageNext(state);
    case SET_SORT_ORDER:
      return setSortOrder(state, action.payload);
    default:
      return state;
  }
};

export default exchanges;
