import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './ui/App';
import * as serviceWorker from './registerServiceWorker';
import { IntlProvider } from 'react-intl';
import 'core-js/stable';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/ja';
import '@formatjs/intl-pluralrules/dist/locale-data/ko';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';

import localeData from './locales/data.json';

// Standard which comes with CRA
// ReactDOM.render(<App />, document.getElementById('root'));

const SUPPORTED_LANGS = ['en', 'es', 'jp', 'ko', 'ru', 'zh'];

let language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

if (!SUPPORTED_LANGS.includes(language)) language = 'en';

const pathLanguage = window.location.pathname
  .split('/')
  .filter(e => SUPPORTED_LANGS.includes(e))
  .pop();

// Split locales with a region code
const languageWithoutRegionCode =
  pathLanguage ||
  localStorage.getItem('currentLocale') ||
  language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to 'en'
const messages =
  localeData[languageWithoutRegionCode] ||
  localeData[language] ||
  localeData.en;

// Variation to enable hot reloading
function render(Component) {
  const app = (
    <IntlProvider locale={language} messages={messages}>
      <App />
    </IntlProvider>
  );
  createRoot(document.getElementById('root')).render(app);
}

render(App);
if (module.hot) {
  module.hot.accept('./ui/App', () => {
    const NextApp = require('./ui/App').default;
    render(NextApp);
  });
}

serviceWorker.register({
  onUpdate: async registration => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      // Makes Workbox call skipWaiting()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload();
    }
  }
});
