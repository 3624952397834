// @flow
import _chunk from 'lodash/chunk';
import { DateTime } from 'luxon';
import defaultImg from '../../ui/components/News/default.png';

import {
  FETCH_NEWS_BEGIN,
  FETCH_NEWS_FAIL,
  FETCH_NEWS_SUCCESS,
  FETCH_LATEST_NEWS_BEGIN,
  FETCH_LATEST_NEWS_SUCCESS,
  FETCH_LATEST_NEWS_FAIL,
  NEXT_NEWS_PAGE,
  SET_NEWS_PAGE,
  PAGE_SIZE
} from './constants';

export type State = {
  currentPage: number,
  totalPages: number,
  latest: {
    fetching: number,
    lastUpdate: number,
    items: [
      {
        title: string,
        url: string,
        thumbnail: string,
        author: string
      }
    ]
  },
  [symbol: string]: {
    fetching: number,
    lastUpdate: number,
    items: [
      {
        title: string,
        url: string,
        thumbnail: string,
        author: string
      }
    ]
  }
};

export const initialState: State = {
  currentPage: 0,
  totalPages: 0
};

// logic
const fetchNewsBegin = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 1 } };
};
const fetchNewsFail = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 0 } };
};
const fetchLatestNewsBegin = (state: State): State => {
  return { ...state, latest: { fetching: 1 } };
};
const fetchLatestNewsFail = (state: State): State => {
  return { ...state, latest: { fetching: 0 } };
};

const fetchNewsSuccess = (state: State, data: Object): State => {
  const symbol = data.symbol;
  const news = data.news;
  const totalPages = _chunk(news, PAGE_SIZE).length - 1;
  const newsArray = news.map(item => {
    const pubDate = item['pubDate'][0] || DateTime.local();
    const relativeDate = DateTime.fromRFC2822(pubDate).toRelative();
    return {
      title: item.title[0],
      url: item.link,
      thumbnail: item['post-thumbnail']
        ? item['post-thumbnail'][0].url[0]
        : item['bnmedia:post-thumbnail']
        ? item['bnmedia:post-thumbnail'][0]['bnmedia:url'][0]
        : defaultImg,
      author: item['dc:creator'][0],
      pub_date: relativeDate
    };
  });

  const entry = {
    fetching: 2,
    lastUpdate: +new Date(),
    items: newsArray
  };

  return {
    ...state,
    totalPages,
    [symbol]: entry
  };
};

const fetchLatestNewsSuccess = (state: State, data: Object): State => {
  const news = data.news;
  const totalPages = _chunk(news, PAGE_SIZE).length - 1;

  const newsArray = news.map(item => {
    const pubDate = item['pubDate'][0] || DateTime.local();
    const relativeDate = DateTime.fromRFC2822(pubDate).toRelative();
    return {
      title: item.title[0],
      url: item.link,
      thumbnail: item['post-thumbnail']
        ? item['post-thumbnail'][0].url[0]
        : item['bnmedia:post-thumbnail']
        ? item['bnmedia:post-thumbnail'][0]['bnmedia:url'][0]
        : defaultImg,
      author: item['dc:creator'][0],
      pub_date: relativeDate
    };
  });

  const entry = {
    fetching: 2,
    lastUpdate: +new Date(),
    items: newsArray
  };

  return {
    ...state,
    totalPages,
    latest: entry
  };
};

const pageNextNews = (state: State): State => {
  if (state.totalPages === 0) return { ...state, currentPage: 0 };
  return {
    ...state,
    currentPage:
      state.totalPages > state.currentPage
        ? state.currentPage + 1
        : state.currentPage
  };
};

const resetNewsPage = (state: State): State => {
  return {
    ...state,
    currentPage: 0
  };
};

// reducer
const news = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_NEWS_BEGIN:
      return fetchNewsBegin(state, action.payload);
    case FETCH_NEWS_SUCCESS:
      return fetchNewsSuccess(state, action.payload);
    case FETCH_NEWS_FAIL:
      return fetchNewsFail(state, action.payload);
    case FETCH_LATEST_NEWS_BEGIN:
      return fetchLatestNewsBegin(state);
    case FETCH_LATEST_NEWS_SUCCESS:
      return fetchLatestNewsSuccess(state, action.payload);
    case FETCH_LATEST_NEWS_FAIL:
      return fetchLatestNewsFail(state);
    case NEXT_NEWS_PAGE:
      return pageNextNews(state, action.payload);
    case SET_NEWS_PAGE:
      return resetNewsPage(state, action.payload);
    default:
      return state;
  }
};

export default news;
