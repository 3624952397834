// @flow

import _chunk from 'lodash/chunk';

import { PROMOTED_ASSET, PROMOTED_AFTER_POSITION } from 'utils/promoted';
import {
  FETCH_BITCOINS,
  FETCH_BITCOINS_SUCCESS,
  FETCH_BITCOINS_FAIL,
  FETCH_TICKER_ALL,
  FETCH_TICKER_ALL_FAIL,
  FETCH_TICKER_ALL_SUCCESS,
  FETCH_COIN_TYPE,
  FETCH_COIN_TYPE_FAIL,
  FETCH_COIN_TYPE_SUCCESS,
  PAGE_NEXT,
  PAGE_PREV,
  GOTO_PAGE,
  RESET_PAGE,
  SET_COIN_FILTER_TYPE,
  SET_SORT_ORDER,
  PAGE_SIZE
} from './constants';

export type PriceShape = {
  id: string,
  imageUrl: string,
  ['24hVolumeUsd']: number,
  availableSupply: number,
  lastUpdated: string,
  marketCapUsd: number,
  maxSupply: ?number,
  name: string,
  percentChange1h: number,
  percentChange24h: number,
  percentChange7d: number,
  priceBtc: number,
  priceUsd: number,
  rank: string,
  symbol: string
};

export type SortType =
  | 'rankedIds'
  | 'rankedPrices'
  | 'rankedVolume'
  | 'rankedChange'
  | 'rankedChange7d';

export type State = {
  fetching: boolean,
  fetchingAll: boolean,
  fetchingFiltered: boolean,
  currentPage: number,
  totalPages: number,
  lastUpdate: number,
  sort: {
    by: SortType,
    order: 'asc' | 'desc'
  },
  byId: {
    [currency_symbol: string]: PriceShape
  },
  bitcoins: { BTC: number, BCH: number },
  rankedIds: string[],
  rankedPrices: string[],
  rankedVolume: string[],
  rankedChange: string[],
  coinIds: string[],
  tokenIds: string[]
};

export const initialState: State = {
  byId: {},
  fetching: false,
  fetchingAll: false,
  fetchingFiltered: false,
  totalPages: 0,
  currentPage: 0,
  lastUpdate: 0,
  sort: { by: 'rankedIds', order: 'desc' },
  bitcoins: { BTC: 0, BCH: 0 },
  rankedIds: [],
  rankedPrices: [],
  rankedVolume: [],
  rankedChange: []
};

// utils
const filterTickers = (list: string[]) => {
  let filteredList = list.filter(u => !u.includes('VFARM'));

  if (
    typeof PROMOTED_ASSET === 'string' &&
    typeof PROMOTED_AFTER_POSITION === 'number'
  ) {
    const promotedAssetIndex = filteredList.indexOf(PROMOTED_ASSET);

    if (promotedAssetIndex > PROMOTED_AFTER_POSITION + 1) {
      filteredList = filteredList.filter(u => u !== PROMOTED_ASSET);
      filteredList.splice(PROMOTED_AFTER_POSITION, 0, PROMOTED_ASSET);
    }
  }

  return filteredList;
};

// logic
const fetchTickerAllBegin = (state: State): State => {
  return { ...state, fetchingAll: true };
};
const fetchTickerAllFail = (state: State): State => {
  return { ...state, fetchingAll: false };
};

const fetchTickerAllSuccess = (state: State, data): State => {
  const totalPages = _chunk(data.data['rankedIds'], PAGE_SIZE).length - 1;

  const dataById = data.data.byId;
  return {
    ...state,
    fetchingAll: false,
    lastUpdate: +new Date(),
    totalPages,
    byId: {
      ...state.byId,
      ...dataById
    },
    bitcoins: {
      BCH: dataById.BCH.priceUsd,
      BTC: dataById.BTC.priceUsd
    },
    rankedIds: filterTickers(data.data['rankedIds']),
    rankedPrices: filterTickers(data.data['rankedPrices']),
    rankedVolume: filterTickers(data.data['rankedVolume']),
    rankedChange: filterTickers(data.data['rankedChange']),
    rankedChange7d: filterTickers(
      data.data['rankedPrices'].sort((a, b) => {
        const changeA = data.data['byId'][a]?.percentChange7d;
        const changeB = data.data['byId'][b]?.percentChange7d;

        if (typeof changeA !== 'number' || typeof changeB !== 'number') {
          return 0;
        }

        return changeB - changeA;
      })
    )
  };
};

const fetchCoinTypeBegin = (state: State): State => {
  return { ...state, fetchingFiltered: true };
};

const fetchCoinTypeFail = (state: State): State => {
  return { ...state, fetchingFiltered: false };
};

const fetchCoinTypeSuccess = (
  state: State,
  payload: { data: Object, type: string }
): State => {
  const data = payload.data;
  const type = payload.type;

  let ret = {
    ...state,
    fetchingFiltered: false
  };

  ret[`${type}Ids`] = data.data.rankedIds;

  return ret;
};

const fetchBitcoinPriceBegin = (state: State): State => {
  return { ...state };
};

const fetchBitcoinPriceFail = (state: State): State => {
  return { ...state };
};

const fetchBitcoinPriceSuccess = (
  state: State,
  payload: { data: Object }
): State => {
  const data = payload.data;
  return {
    ...state,
    bitcoins: data.data
  };
};

const gotoPage = (state: State, payload: number): State => {
  const page = payload.page;
  return {
    ...state,
    currentPage: page
  };
};

const resetPage = (state: State): State => {
  return {
    ...state,
    currentPage: 0
  };
};

const pageNext = (state: State): State => {
  if (state.totalPages === 0) return { ...state, currentPage: 0 };
  return {
    ...state,
    currentPage:
      state.totalPages > state.currentPage
        ? state.currentPage + 1
        : state.currentPage
  };
};

const pagePrev = (state: State): State => {
  if (state.totalPages === 0) return { ...state, currentPage: 0 };
  return {
    ...state,
    currentPage:
      state.currentPage === 0 ? state.currentPage : state.currentPage - 1
  };
};

const setSortOrder = (state: State, sortBy: SortType): State => {
  const order =
    sortBy === state.sort.by
      ? state.sort.order === 'asc'
        ? 'desc'
        : 'asc'
      : 'desc';

  return {
    ...state,
    currentPage: 0,
    sort: {
      by: sortBy,
      order: order
    }
  };
};

const setCoinFilterType = (state: State, payload: string): State => {
  return {
    ...state,
    coinFilterType: payload
  };
};

// reducer
const prices = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_TICKER_ALL:
      return fetchTickerAllBegin(state);
    case FETCH_TICKER_ALL_SUCCESS:
      return fetchTickerAllSuccess(state, action.payload);
    case FETCH_TICKER_ALL_FAIL:
      return fetchTickerAllFail(state);
    case FETCH_COIN_TYPE:
      return fetchCoinTypeBegin(state);
    case FETCH_COIN_TYPE_SUCCESS:
      return fetchCoinTypeSuccess(state, action.payload);
    case FETCH_COIN_TYPE_FAIL:
      return fetchCoinTypeFail(state);
    case FETCH_BITCOINS:
      return fetchBitcoinPriceBegin(state);
    case FETCH_BITCOINS_SUCCESS:
      return fetchBitcoinPriceSuccess(state, action.payload);
    case FETCH_BITCOINS_FAIL:
      return fetchBitcoinPriceFail(state);
    case GOTO_PAGE:
      return gotoPage(state, action.payload);
    case PAGE_NEXT:
      return pageNext(state);
    case PAGE_PREV:
      return pagePrev(state);
    case RESET_PAGE:
      return resetPage(state);
    case SET_COIN_FILTER_TYPE:
      return setCoinFilterType(state, action.payload);
    case SET_SORT_ORDER:
      return setSortOrder(state, action.payload);
    default:
      return state;
  }
};

export default prices;
