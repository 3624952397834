// @flow

export const FETCH_EXCHANGE_RATES_BEGIN = 'fiat::FETCH_EXCHANGE_RATES_START';
export const FETCH_EXCHANGE_RATES_SUCCESS =
  'fiat::FETCH_EXCHANGE_RATES_SUCCESS';
export const FETCH_EXCHANGE_RATES_FAIL = 'fiat::FETCH_EXCHANGE_RATES_FAIL';

export const FIAT_DEFAULTS = ['USD', 'EUR', 'GBP', 'JPY', 'CNY', 'AUD', 'HKD'];
export const FIAT_ALL = [
  'AED',
  'ARS',
  'AUD',
  'BGN',
  'BHD',
  'BND',
  'BRL',
  'BWP',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'ETH',
  'EUR',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IRR',
  'ISK',
  'JPY',
  'KRW',
  'KWD',
  'KZT',
  'LKR',
  'LYD',
  'MUR',
  'MXN',
  'MYR',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PHP',
  'PKR',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TTD',
  'TWD',
  'USD',
  'VEF',
  'ZAR'
];
