// @flow

import {
  FETCH_EXCHANGE_RATES_BEGIN,
  FETCH_EXCHANGE_RATES_FAIL,
  FETCH_EXCHANGE_RATES_SUCCESS
} from './constants';

export type State = {
  fetching: number,
  lastUpdate: number,
  byId: {
    [fiat_id: string]: number
  }
};

export const initialState: State = {
  byId: {},
  fetching: 0, // 0 default, 1 = fetching, 2 = fetched
  lastUpdate: 0
};

// logic
const fetchExchangeRatesBegin = (state): State => {
  return { ...state, fetching: 1 };
};

const fetchExchangeRatesFail = (state): State => {
  return { ...state, fetching: 0 };
};

const fetchExchangeRatesSuccess = (state, data): State => {
  const dataObj = {};
  data.forEach(element => {
    const symbol = element.code;
    const price = element.rate;
    dataObj[symbol] = price;
  });
  return {
    ...state,
    lastUpdate: +new Date(),
    fetching: 2,
    byId: dataObj
  };
};

// reducer
const fiat = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_EXCHANGE_RATES_BEGIN:
      return fetchExchangeRatesBegin(state);
    case FETCH_EXCHANGE_RATES_SUCCESS:
      return fetchExchangeRatesSuccess(state, action.payload);
    case FETCH_EXCHANGE_RATES_FAIL:
      return fetchExchangeRatesFail(state);
    default:
      return state;
  }
};

export default fiat;
