// @flow

import {
  FETCH_MINUTE_DAY,
  FETCH_MINUTE_DAY_SUCCESS,
  FETCH_MINUTE_DAY_FAIL
} from './constants';

export type MinutelyPriceShape = {
  time: number,
  open: number,
  close: number,
  high: number,
  low: number,
  value: number
};

export type State = {
  byId: {
    [currency_symbol: string]: {
      fetching: boolean,
      lastUpdate: number,
      history: MinutelyPriceShape[]
    }
  }
};

export const initialState: State = {
  byId: {}
};

// logic
const fetchMinutelyBegin = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: true
        }
      }
    }
  };
};

const fetchMinutelyFail = (
  state: State,
  payload: { symbol: string, curr: string }
): State => {
  const symbol = payload.symbol;
  const curr = payload.curr;
  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          fetching: false
        }
      }
    }
  };
};

const fetchMinutelySuccess = (
  state: State,
  payload: {
    data: MinutelyPriceShape[],
    symbol: string,
    curr: string
  }
): State => {
  const data = payload.data.reverse();
  const symbol = payload.symbol;
  const curr = payload.curr;

  if (!data.length) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [symbol]: {
          ...state.byId[symbol],
          [curr]: {
            ...state.byId[symbol][curr],
            fetching: false
          }
        }
      }
    };
  }

  const cleaned = data
    .map(item => {
      return {
        open: item.open,
        time: item.time,
        close: item.close,
        high: item.high,
        low: item.low,
        value: item.close
      };
    })
    .filter(item => item.open);

  return {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        [curr]: {
          ...state.byId[symbol][curr],
          history: cleaned,
          fetching: false,
          lastUpdate: +new Date()
        }
      }
    }
  };
};

// reducer
const minutely = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_MINUTE_DAY:
      return fetchMinutelyBegin(state, action.payload);
    case FETCH_MINUTE_DAY_SUCCESS:
      return fetchMinutelySuccess(state, action.payload);
    case FETCH_MINUTE_DAY_FAIL:
      return fetchMinutelyFail(state, action.payload);
    default:
      return state;
  }
};

export default minutely;
