// @flow

import { css } from 'styled-components';

export const easeOut = css`
  transition: all 200ms ease-out;
`;

export const easeInOut = css`
  transition: all 300ms ease-in-out;
`;

const transitions = {
  easeInOut,
  easeOut
};

export default transitions;
