// @flow
import styled, { css } from 'styled-components';

import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import transitions from 'ui/styles/transitions';

import Text from './Text';

const Link = styled(Text.withComponent('a'))`
  color: ${colors.solid.primaryBlue};
  text-decoration: none;
  display: inline;
  padding: 2px 0;
  line-height: 29px;
  border-bottom: 2px solid;
  border-color: transparent;
  ${transitions.easeOut};

  &:hover {
    border-color: ${colors.solid.primaryBlue};
  }

  ${props =>
    props.muted &&
    css`
      color: ${props => props.theme.muted};
      &:hover {
        border-color: ${props => props.theme.muted};
      }
    `};
`;

export default Link;
