// @flow

export const FETCH_HOURLY_BATCH = 'hourly::FETCH_HOURLY_BATCH';
export const FETCH_HOURLY_BATCH_SUCCESS = 'hourly::FETCH_HOURLY_BATCH_SUCCESS';
export const FETCH_HOURLY_BATCH_FAIL = 'hourly::FETCH_HOURLY_BATCH_FAIL';

export const FETCH_HOURLY_WEEK = 'hourly::FETCH_HOURLY_WEEK';
export const FETCH_HOURLY_WEEK_SUCCESS = 'hourly::FETCH_HOURLY_WEEK_SUCCESS';
export const FETCH_HOURLY_WEEK_FAIL = 'hourly::FETCH_HOURLY_WEEK_FAIL';

export const FETCH_HOURLY_MONTH = 'hourly::FETCH_HOURLY_MONTH';
export const FETCH_HOURLY_MONTH_SUCCESS = 'hourly::FETCH_HOURLY_MONTH_SUCCESS';
export const FETCH_HOURLY_MONTH_FAIL = 'hourly::FETCH_HOURLY_MONTH_FAIL';
