// @flow

import styled from 'styled-components';
import { media } from '../styles/utils';
import spacing from '../styles/spacing';

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: ${props => (props.margin ? props.margin : '0 auto')};
  flex-direction: column;
  max-width: 100%;
  padding: 0 ${spacing.none}px;
  ${media.large`
    max-width: 1200px;
    padding: ${spacing.none}px;
  `};
`;

export default Container;
