// @flow

import styled from 'styled-components';
import H1 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H1';
import { css } from 'styled-components';
import { media } from 'ui/styles/utils';

export const headerBase = css`
  color: ${props => props.theme.foreground};
  font-weight: ${props => (props.thin ? 400 : 600)};
  font-size: 40px;
  margin: 0px 16px;
  ${props =>
    props.center &&
    css`
      margin: 0 auto;
      text-align: center;
    `};
  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `};
  ${media.medium`
    font-size: 64px;
    margin: 0px;
  `};
`;

const Heading1 = styled(H1)`
  ${headerBase};
`;
Heading1.displayName = 'H1';

export default Heading1;
