// @flow

import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Provider, connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import BrowserDetection from 'react-browser-detection';
import 'core-js';
import Cookies from 'js-cookie';

import styled, { ThemeProvider } from 'styled-components';

import { ConnectedRouter } from 'connected-react-router';
import { createGlobalStyle } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

import { persistor, history } from 'data/store';
import store from 'data/store';

import { AppStyle } from './styles/global';
import { themeLight, themeDark } from './styles/theme';
import { getTheme } from 'data/theme/selectors';
import { initAmplitude, sendAmpEvent } from 'utils/analytics';

import ErrorPage from './pages/Error';
import Link from 'ui/atoms/Link';

/* eslint-disable */
import themes, {
  GlobalStyle,
  media
} from '@bitcoin-portal/bitcoincom-pkg-theme';
/* eslint-enable */

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

const FontLoader = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props =>
    props.contrast ? colors.solid.vulcan : colors.solid.bgGrey};
`;

const Incompatible = styled.div`
  padding: 40px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  height: 100vw;
`;

// Global styles
const UnivStyle = createGlobalStyle`
  #universal-footer {
    margin-top: 0 !important;
  }
  #desktop-give-feedback {
    display: none !important;
  }
  .btctm-top {
    position: absolute !important;
  }
  * {
    transition: background-color 0.1s linear, color 0.1s linear;
  }

  button.osano-cm-widget {
    display: none;
  }

  .osano-cm-dialog--type_box.osano-cm-dialog--position_bottom-right {
    bottom: 0;
    right: 0;
    max-width: unset;
    width: 100%;
    background: #FFFFFF;
    ${media.md`
      bottom: 1em;
      right: 1em;
      max-width: 20em;
      width: calc(100vw - 2em);
      background: rgba(255,255,255,0.7);
    `}
  }
`;

const HomePage = lazy(() => import('./pages/Home'));
const DetailPage = lazy(() => import('./pages/Detail'));
const DeFi = lazy(() => import('./pages/DeFi'));
const Exchanges = lazy(() => import('./pages/Exchanges'));
const ExchangeDetail = lazy(() => import('./pages/ExchangeDetail'));
const GainersLosers = lazy(() => import('./pages/GainersLosers'));
const WalletDetailPage = lazy(() => import('./pages/WalletDetail'));

const browserHandler = {
  ie: () => (
    <Incompatible>
      Your browser is not currently supported, please update to a newer browser
      to use this application
      <br />
      Download{' '}
      <Link href="https://www.google.com/chrome/" target="_blank">
        Chrome
      </Link>{' '}
      or{' '}
      <Link href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
        Firefox
      </Link>
    </Incompatible>
  ),
  default: browser => (
    <Router history={history}>
      <Suspense fallback={<ErrorPage loading={true} />}>
        <Switch>
          <Route path="/:lang([a-z]{2})/">
            {({ match, location }) => {
              const params = match ? match.params : {};
              const { lang } = params;
              const assignedLocale = lang || 'en';
              const cookieLocale = lang === 'jp' ? 'ja' : lang || 'en';

              localStorage.setItem('currentLocale', assignedLocale);
              Cookies.set(
                'currentLocale',
                cookieLocale,
                process.env.NODE_ENV === 'development'
                  ? {}
                  : {
                      domain: 'bitcoin.com',
                      secure: true,
                      expires: 365
                    }
              );

              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: {
                      localeFromPath: !!lang
                    }
                  }}
                />
              );
            }}
          </Route>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/coins/"
            render={props => <HomePage {...props} cryptoType={'coin'} />}
          />
          <Route
            exact
            path="/tokens/"
            render={props => <HomePage {...props} cryptoType={'token'} />}
          />
          <Route
            exact
            path="/btcbch/"
            render={props => <HomePage {...props} showBitcoinsOnly={true} />}
          />
          <Route
            sensitive
            path="/crypto/:coinSymbol(.*[a-z].*)"
            render={props => (
              <Redirect to={`${props.match.params.coinSymbol.toUpperCase()}`} />
            )}
          />
          <Route exact path="/exchanges/" component={Exchanges} />
          <Route exact path="/de-fi-tokens/" component={DeFi} />
          <Route exact path="/gainers-losers/" component={GainersLosers} />
          <Route exact path="/crypto/:coinSymbol/" component={DetailPage} />
          <Route
            exact
            path="/wallet/crypto/:coinSymbol/"
            component={WalletDetailPage}
          />
          <Route
            exact
            path="/exchanges/:exchangeSymbol/"
            component={ExchangeDetail}
          />
          <Route exact path="/tokens/:coinSymbol/" component={DetailPage} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Suspense>
    </Router>
  )
};

const themeMap = {
  light: themeLight,
  dark: themeDark
};
type InnerAppProps = {
  activeTheme: string
};
class InnerApp extends React.Component<InnerAppProps> {
  render() {
    const { activeTheme } = this.props;
    const theme = themeMap[activeTheme] || themeLight;
    const providerTheme = themes[activeTheme] || themes.light;

    return (
      <ThemeProvider theme={providerTheme}>
        <ThemeProvider theme={theme}>
          <FontLoader contrast={activeTheme === 'dark'}>
            <BrowserDetection>{browserHandler}</BrowserDetection>
          </FontLoader>
        </ThemeProvider>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = state => {
  return {
    activeTheme: getTheme(state)
  };
};

// TODO - Think of a better/cleaner place to put the themeConnection, ideally won't need withRouter
const ThemeConnectedApp = withRouter(connect(mapStateToProps)(InnerApp));

type Props = {};

class App extends React.Component<Props> {
  render() {
    initAmplitude();
    sendAmpEvent({
      label: 'product_page_view',
      properties: {
        product: 'markets'
      }
    });
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <GoogleTagManager gtmId={'GTM-5N6TL56'} /> */}
          <ConnectedRouter history={history}>
            <ThemeConnectedApp />
          </ConnectedRouter>
          <AppStyle />
          <UnivStyle />
        </PersistGate>
      </Provider>
    );
  }
}

export { ThemeConnectedApp };
export default App;
