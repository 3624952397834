// @flow

import {
  FETCH_SUPPLY,
  FETCH_SUPPLY_SUCCESS,
  FETCH_SUPPLY_FAIL
} from './constants';

export type State = {
  [symbol: string]: {
    fetching: number,
    lastUpdate: number,
    items: [
      {
        time: number,
        supply: number
      }
    ]
  }
};

export const initialState: State = {};

const fetchSupplyBegin = (state: State, symbol: string): State => {
  return { ...state };
};

const fetchSupplyFail = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 0 } };
};

const fetchSupplySuccess = (state: State, data: Object): State => {
  return { ...state, [data.symbol]: data.supply };
};

const supply = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_SUPPLY:
      return fetchSupplyBegin(state, action.payload);
    case FETCH_SUPPLY_SUCCESS:
      return fetchSupplySuccess(state, action.payload);
    case FETCH_SUPPLY_FAIL:
      return fetchSupplyFail(state, action.payload);
    default:
      return state;
  }
};

export default supply;
