// @flow

import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAIL
} from './constants';

export type State = {
  [symbol: string]: {
    fetching: number,
    lastUpdate: number,
    items: [
      {
        time: number,
        transactions: number
      }
    ]
  }
};

export const initialState: State = {};

const fetchTransactionsBegin = (state: State, symbol: string): State => {
  return { ...state };
};

const fetchTransactionsFail = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 0 } };
};

const fetchTransactionsSuccess = (state: State, data: Object): State => {
  return { ...state, [data.symbol]: data.transactions };
};

const transactions = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_TRANSACTIONS:
      return fetchTransactionsBegin(state, action.payload);
    case FETCH_TRANSACTIONS_SUCCESS:
      return fetchTransactionsSuccess(state, action.payload);
    case FETCH_TRANSACTIONS_FAIL:
      return fetchTransactionsFail(state, action.payload);
    default:
      return state;
  }
};

export default transactions;
