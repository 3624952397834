// @flow

export const SELECT_FIAT = 'meta::SELECT_FIAT';
export const SELECT_TIME = 'meta::SELECT_TIME';
export const SET_FILTER_TEXT = 'meta::SET_FILTER_TEXT';
export const SET_SYMBOL_EXPAND = 'meta::SET_SYMBOL_EXPAND';
export const FILTER_FAVORITES = 'meta::FILTER_FAVORITES';
export const CLEAR_FILTERS = 'meta::CLEAR_FILTERS';

// Buy & Sell restricted in OFAC countries
export const RESTRICTED_REGIONS = [
  'AL',
  'BA',
  'XK',
  'BY',
  'MM',
  'CI',
  'CU',
  'CD',
  'IR',
  'IQ',
  'LR',
  'KP',
  'SD',
  'SY',
  'ZW'
];
