// @flow

import {
  FETCH_QUOTES,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAIL,
  FETCH_QUOTE_HISTORY,
  FETCH_QUOTE_HISTORY_SUCCESS,
  FETCH_QUOTE_HISTORY_FAIL
} from './constants';

export type State = {
  fetchingHistory: boolean,
  percentageMove: {
    marketCapChange: number,
    volumeChange: number
  },
  metadata: [
    {
      marketCap: number,
      volume: number,
      time: number
    }
  ],
  [symbol: string]: {
    [currency: string]: {
      fetching: number,
      lastUpdate: number,
      items: [
        {
          exchange: string,
          price_usd: number,
          volume: number
        }
      ]
    }
  }
};

export const initialState: State = {
  fetchingHistory: false,
  percentageMove: {
    marketCapChange: 0.0,
    volumeChange: 0.0
  },
  metadata: []
};

const fetchQuotesBegin = (state: State, symbol: string): State => {
  return { ...state };
};

const fetchQuotesFail = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 0 } };
};

const fetchQuotesSuccess = (state: State, data: Object): State => {
  return { ...state, [data.symbol]: data.quotes };
};

const fetchQuoteHistoryBegin = (state: State): State => {
  return {
    ...state,
    fetchingHistory: true
  };
};

const fetchQuoteHistoryFail = (state: State): State => {
  return {
    ...state,
    fetchingHistory: false
  };
};

const fetchQuoteHistorySuccess = (state: State, data: Object): State => {
  return {
    ...state,
    percentageMove: data.percentageMove,
    metadata: data.metadata
  };
};

const quotes = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_QUOTES:
      return fetchQuotesBegin(state, action.payload);
    case FETCH_QUOTES_SUCCESS:
      return fetchQuotesSuccess(state, action.payload);
    case FETCH_QUOTES_FAIL:
      return fetchQuotesFail(state, action.payload);
    case FETCH_QUOTE_HISTORY:
      return fetchQuoteHistoryBegin(state);
    case FETCH_QUOTE_HISTORY_SUCCESS:
      return fetchQuoteHistorySuccess(state, action.payload);
    case FETCH_QUOTE_HISTORY_FAIL:
      return fetchQuoteHistoryFail(state);
    default:
      return state;
  }
};

export default quotes;
