// @flow

import {
  SELECT_FIAT,
  SELECT_TIME,
  SET_FILTER_TEXT,
  SET_SYMBOL_EXPAND,
  FILTER_FAVORITES,
  CLEAR_FILTERS
} from './constants';

import { FIAT_DEFAULTS } from '../fiat/constants';

export type Times = '1D' | '7D' | '1M' | '6M' | '1Y' | '5Y';
export type Fiats = FIAT_DEFAULTS;

export type State = {
  allTimes: string[],
  selectedTime: Times,
  selectedFiat: Fiats,
  expandedSymbol: string,
  filterText: string,
  filterFavorites: boolean
};

export const initialState: State = {
  allTimes: ['1D', '7D', '1M', '6M', '1Y', '5Y'],
  selectedTime: '7D',
  selectedFiat: 'USD',
  expandedSymbol: '',
  filterText: '',
  filterFavorites: false
};

const setTime = (state: State, time: Times): State => {
  const { allTimes } = state;
  if (allTimes.includes(time)) {
    return {
      ...state,
      selectedTime: time
    };
  }

  return { ...state };
};

const setFiatCurrency = (state: State, curr: Fiats): State => {
  localStorage.setItem('fiatCurrency', curr);
  return {
    ...state,
    selectedFiat: curr
  };
};

const setFilterText = (state: State, payload: string): State => {
  return {
    ...state,
    filterText: payload
  };
};

const setSymbolExpand = (state: State, payload: string): State => {
  return {
    ...state,
    expandedSymbol: payload
  };
};

const setFilterFavorites = (state: State): State => {
  return { ...state, filterFavorites: !state.filterFavorites };
};

const setClearFilters = (state: State): State => {
  return {
    ...state,
    filterFavorites: false,
    filterText: ''
  };
};

// reducer
const meta = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case SELECT_FIAT:
      return setFiatCurrency(state, action.curr);
    case SELECT_TIME:
      return setTime(state, action.time);
    case SET_FILTER_TEXT:
      return setFilterText(state, action.payload);
    case SET_SYMBOL_EXPAND:
      return setSymbolExpand(state, action.payload);
    case FILTER_FAVORITES:
      return setFilterFavorites(state);
    case CLEAR_FILTERS:
      return setClearFilters(state);
    default:
      return state;
  }
};

export default meta;
