// @flow

export const FETCH_EXCHANGES = 'exchanges:FETCH_EXCHANGES';
export const FETCH_EXCHANGES_SUCCESS = 'exchanges:FETCH_EXCHANGES_SUCCESS';
export const FETCH_EXCHANGES_FAIL = 'exchanges:FETCH_EXCHANGES_FAIL';

export const FETCH_EXCHANGE_HISTORY = 'exchanges:FETCH_EXCHANGE_HISTORY';
export const FETCH_EXCHANGE_HISTORY_SUCCESS =
  'exchanges:FETCH_EXCHANGE_HISTORY_SUCCESS';
export const FETCH_EXCHANGE_HISTORY_FAIL =
  'exchanges:FETCH_EXCHANGE_HISTORY_FAIL';

export const FETCH_EXCHANGE_PAIRS = 'exchanges:FETCH_EXCHANGES_PAIRS';
export const FETCH_EXCHANGE_PAIRS_SUCCESS =
  'exchanges:FETCH_EXCHANGE_PAIRS_SUCCESS';
export const FETCH_EXCHANGE_PAIRS_FAIL = 'exchanges:FETCH_EXCHANGE_PAIRS_FAIL';
export const FETCH_EXCHANGE_PAIR_HISTORY =
  'exchanges:FETCH_EXCHANGE_PAIR_HISTORY';
export const FETCH_EXCHANGE_PAIR_HISTORY_SUCCESS =
  'exchanges:FETCH_EXCHANGE_PAIR_HISTORY_SUCCESS';
export const FETCH_EXCHANGE_PAIR_HISTORY_FAIL =
  'exchanges:FETCH_EXCHANGE_PAIR_HISTORY_FAIL';

export const SET_SORT_ORDER = 'exchanges:SET_SORT_ORDER';

export const GOTO_PAGE = 'exchanges:GOTO_PAGE';
export const PAGE_NEXT = 'exchanges:PAGE_NEXT';
export const PAGE_SIZE = 10;

export const EXCHANGE_AFFILIATES = {
  coinbase: 'http://coinbase-consumer.sjv.io/oBbXY',
  coinex: 'https://www.coinex.com/account/signup?refer_code=4wbu6',
  binance: 'https://www.binance.com/?ref=36197831',
  'bitcoin-com': 'https://app.bitcoin.com',
  bitmex: 'https://www.bitmex.com/register/alSYfE',
  digifinex: 'https://www.digifinex.com/en-ww/from/iPRWoN/2852****1490',
  bitz: 'https://u.bit-z.com/register?invite_code=2530489',
  coinbene:
    'https://www.coinbene.com/login/register.html#/?type=invitation&id=56233975&site=MAIN',
  bitforex: 'https://www.bitforex.com/invitationRegister?inviterId=2003875',
  bw: 'https://www.bw.com/regGetCommission/N2tlTUNCbklITkE=?lan=en',
  idax: 'https://www.idax.mn/#/register?inviteCode=7zenor',
  okex: 'https://www.okex.me/account/register?channelFlag=AC1500667989'
};
