// @flow
import _omit from 'lodash/omit';

import { PROMOTED_AFTER_POSITION, PROMOTED_ASSET } from '../../utils/promoted';
import {
  FETCH_TOKEN_TICKER_ALL,
  FETCH_TOKEN_TICKER_ALL_SUCCESS,
  FETCH_TOKEN_TICKER_ALL_FAIL,
  FETCH_TOKEN,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAIL,
  HIDDEN_TOKENS,
  FETCH_TOKEN_PLATFORM,
  FETCH_TOKEN_PLATFORM_SUCCESS,
  FETCH_TOKEN_PLATFORM_FAIL,
  SET_SORT_ORDER
} from './constants';

export type SortType =
  | 'rankedIds'
  | 'rankedPrices'
  | 'rankedVolume'
  | 'rankedChange'
  | 'rankedChange7d';

export type TokenPriceShape = {
  time: number,
  open: number,
  close: number,
  high: number,
  low: number,
  volume: number
};

export type TokenShape = {
  id: string,
  symbol: string,
  name: string,
  supply: number,
  history: TokenPriceShape[],
  lastUpdate: number
};

export type State = {
  fetching: boolean,
  fetchingAll: boolean,
  sort: {
    by: SortType,
    order: 'asc' | 'desc'
  },
  byId: {
    [token_symbol: string]: TokenShape
  },
  byPlatform: {
    [platform_symbol: string]: string[]
  },
  rankedIds: string[],
  rankedPrices: string[],
  rankedVolume: string[],
  rankedChange: string[],
  fetchingPlatform: boolean
};

export const initialState: State = {
  byId: {},
  byPlatform: {},
  fetching: false,
  fetchingAll: false,
  sort: { by: 'rankedIds', order: 'desc' },
  rankedIds: [],
  rankedPrices: [],
  rankedVolume: [],
  rankedChange: [],
  fetchingPlatform: false
};

const filterTokens = (list: string[]) => {
  let filteredList = list.filter(u => !HIDDEN_TOKENS.includes(u));

  if (
    typeof PROMOTED_ASSET === 'string' &&
    typeof PROMOTED_AFTER_POSITION === 'number'
  ) {
    const promotedAssetIndex = filteredList.indexOf(PROMOTED_ASSET);

    if (promotedAssetIndex > PROMOTED_AFTER_POSITION + 1) {
      filteredList = filteredList.filter(u => u !== PROMOTED_ASSET);
      filteredList.splice(PROMOTED_AFTER_POSITION, 0, PROMOTED_ASSET);
    }
  }

  return filteredList;
};

// logic
const fetchTokenTickerAllBegin = (state: State): State => {
  return { ...state, fetchingAll: true };
};

const fetchTokenTickerAllFail = (state: State): State => {
  return { ...state, fetchingAll: false };
};

const fetchTokenTickerAllSuccess = (state: State, data): State => {
  let dataById = data.data.byId;
  dataById = _omit(dataById, 'BRST');
  return {
    ...state,
    fetchingAll: false,
    byId: {
      ...dataById,
      ...state.byId
    },
    rankedIds: filterTokens(data.data['rankedIds']),
    rankedPrices: filterTokens(data.data['rankedPrices']),
    rankedVolume: filterTokens(data.data['rankedVolume']),
    rankedChange: filterTokens(data.data['rankedChange']),
    rankedChange7d: filterTokens(
      data.data['rankedPrices'].sort((a, b) => {
        const changeA = data.data['byId'][a]?.percentChange7d;
        const changeB = data.data['byId'][b]?.percentChange7d;

        if (typeof changeA !== 'number' || typeof changeB !== 'number') {
          return 0;
        }

        return changeB - changeA;
      })
    )
  };
};

const fetchTokenSuccess = (
  state: State,
  payload: {
    data: Object,
    symbol: string
  }
): State => {
  const symbol = payload.symbol;
  const data = payload.data;

  if (!data) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [symbol]: {
          ...state.byId[symbol],
          fetching: false
        }
      }
    };
  }

  const cleaned = data
    .map(item => {
      return {
        time: item.time,
        open: item.open,
        close: item.close,
        high: item.high,
        low: item.low,
        value: item.close,
        volume: item.volume_close
      };
    })
    .filter(item => item.open);

  const ret = {
    ...state,
    byId: {
      ...state.byId,
      [symbol]: {
        ...state.byId[symbol],
        history: cleaned,
        lastUpdate: +new Date(),
        fetching: false
      }
    }
  };

  return ret;
};

const fetchTokenBegin = (state: State, payload: { symbol: string }): State => {
  return {
    ...state,
    fetching: true
  };
};

const fetchTokenFail = (state: State, payload: { symbol: string }): State => {
  return {
    ...state,
    fetching: false
  };
};

const fetchTokenPlatformBegin = (state: State): State => {
  return { ...state, fetchingPlatform: true };
};

const fetchTokenPlatformFail = (state: State): State => {
  return { ...state, fetchingPlatform: false };
};

const fetchTokenPlatformSuccess = (state: State, payload): State => {
  const data = payload.data.data;
  const platform = payload.type;
  return {
    ...state,
    byPlatform: {
      ...state.byPlatform,
      [platform]: {
        ...state.byPlatform[platform],
        symbols: data.rankedIds
      }
    },
    fetchingPlatform: false
  };
};

const setSortOrder = (state: State, sortBy: SortType): State => {
  const order =
    sortBy === state.sort.by
      ? state.sort.order === 'asc'
        ? 'desc'
        : 'asc'
      : 'desc';

  return {
    ...state,
    currentPage: 0,
    sort: {
      by: sortBy,
      order: order
    }
  };
};

// reducer
const tokens = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_TOKEN_TICKER_ALL:
      return fetchTokenTickerAllBegin(state);
    case FETCH_TOKEN_TICKER_ALL_SUCCESS:
      return fetchTokenTickerAllSuccess(state, action.payload);
    case FETCH_TOKEN_TICKER_ALL_FAIL:
      return fetchTokenTickerAllFail(state);
    case FETCH_TOKEN:
      return fetchTokenBegin(state, action.payload);
    case FETCH_TOKEN_SUCCESS:
      return fetchTokenSuccess(state, action.payload);
    case FETCH_TOKEN_FAIL:
      return fetchTokenFail(state, action.payload);
    case FETCH_TOKEN_PLATFORM:
      return fetchTokenPlatformBegin(state);
    case FETCH_TOKEN_PLATFORM_SUCCESS:
      return fetchTokenPlatformSuccess(state, action.payload);
    case FETCH_TOKEN_PLATFORM_FAIL:
      return fetchTokenPlatformFail(state);
    case SET_SORT_ORDER:
      return setSortOrder(state, action.payload);
    default:
      return state;
  }
};

export default tokens;
