// @flow

import React from 'react';

import styled from 'styled-components';
import { NavbarV2 as Nav } from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';

import Container from 'ui/atoms/Container';
import { media } from 'ui/styles/utils';
import H1 from 'ui/atoms/H1';
import H2 from 'ui/atoms/H2';
import Link from 'ui/atoms/Link';
import LoaderDots from 'ui/atoms/LoaderDots';
import Spacer from 'ui/atoms/Spacer';

type Props = {
  loading: boolean
};

type State = {
  loadingData: boolean
};

const Main = styled.div`
  padding: 2rem;
  margin: 3rem auto;
  text-align: center;
  background-color: ${props =>
    props.useBackground ? '#e0f3ef' : 'transparent'};
  border-radius: 20px;
  #loader-circles {
    transform: scale(2);
  }
`;

const AlertLogo = styled.div`
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiPjxnPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI1NiAyNTYpIHNjYWxlKDAuNjkgMC42OSkgcm90YXRlKDApIHRyYW5zbGF0ZSgtMjU2IC0yNTYpIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48c3ZnIGZpbGw9IiMwMDAwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxIDUxIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPkFzc2V0IDk2PC90aXRsZT48ZyBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGRhdGEtbmFtZT0idXNlciBpbnRlcmZhY2UiPjxwYXRoIGQ9Ik0xLjc0LDEyLjM1QTEsMSwwLDAsMSwuNzksMTEsMTYuMSwxNi4xLDAsMCwxLDExLjIuNzRhMSwxLDAsMSwxLC42LDEuOTEsMTQuMDksMTQuMDksMCwwLDAtOS4xMSw5QTEsMSwwLDAsMSwxLjc0LDEyLjM1WiI+PC9wYXRoPjxwYXRoIGQ9Ik0zNSw1MUgxNkExNiwxNiwwLDAsMSwwLDM1VjE2cTAtLjY1LjA1LTEuMjlhMSwxLDAsMSwxLDIsLjE2UTIsMTUuNDMsMiwxNlYzNUExNCwxNCwwLDAsMCwxNiw0OUgzNUExNCwxNCwwLDAsMCw0OSwzNVYxNkExNCwxNCwwLDAsMCwzNSwySDE2Yy0uMzgsMC0uNzYsMC0xLjEzLDBhMSwxLDAsMCwxLTEuMDgtLjkyQTEsMSwwLDAsMSwxNC43MS4wNVExNS4zNSwwLDE2LDBIMzVBMTYsMTYsMCwwLDEsNTEsMTZWMzVBMTYsMTYsMCwwLDEsMzUsNTFaIj48L3BhdGg+PHBhdGggZD0iTTI1LjUsNDQuMzZhNC45Miw0LjkyLDAsMSwxLDQuOTItNC45MkE0LjkyLDQuOTIsMCwwLDEsMjUuNSw0NC4zNlptMC03LjgzYTIuOTIsMi45MiwwLDEsMCwyLjkyLDIuOTJBMi45MiwyLjkyLDAsMCwwLDI1LjUsMzYuNTJaIj48L3BhdGg+PHBhdGggZD0iTTI1LjUsMzIuNDZhNS4xNSw1LjE1LDAsMCwxLTQuOTItNS4zNFY5LjQzYzAtMS42OSwxLjkzLTIuNzksNC45Mi0yLjc5czQuOTIsMS4wOSw0LjkyLDIuNzl2MTcuN0E1LjE1LDUuMTUsMCwwLDEsMjUuNSwzMi40NlptMC0yMy44MmMtMS45MSwwLTIuOTIuNTktMi45Mi43OXYxNy43YTMuMTUsMy4xNSwwLDAsMCwyLjkyLDMuMzQsMy4xNSwzLjE1LDAsMCwwLDIuOTItMy4zNFY5LjQzQzI4LjQyLDkuMjMsMjcuNDEsOC42NCwyNS41LDguNjRaIj48L3BhdGg+PC9nPjwvZz48L3N2Zz48L2c+PC9nPjwvc3ZnPg==')
    center/400px no-repeat;
  display: inline-block;
  width: 340px;
  height: 300px;
  opacity: 0.1;
  ${media.large`
    float: left;
  `};
`;

const Copy = styled.div`
  display: inline-block;
  margin: auto;
  vertical-align: top;
  ${media.medium`
    margin-top: 12px;`};
  ${media.large`
    text-align: left;
    margin-top: 100px;`};
`;

export class ErrorPage extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      loadingData: false,
      loaderTimeoutID: null
    };
  }

  componentDidMount() {
    const timeoutID = setTimeout(() => {
      this.setState({
        loadingData: false
      });
    }, 15000);

    this.setState({
      loaderTimeoutID: timeoutID
    });
  }

  componentWillUnmount() {
    const { loaderTimeoutID } = this.state;
    if (loaderTimeoutID) clearTimeout(loaderTimeoutID);
  }

  render() {
    const { hideNav, msg } = this.props;
    const errorMsg = msg || 'Sorry, this page cannot be found.';
    const { loadingData } = this.state;
    return (
      <div>
        {!hideNav && (
          <Nav
            locale="en"
            link="https://branch.wallet.bitcoin.com/MU4WcvBmqAb"
          />
        )}
        <Container style={{ minHeight: '95vh' }}>
          <Spacer />
          <H1 style={{ marginTop: '36px' }}>Markets</H1>
          <Main useBackground={!loadingData}>
            {loadingData ? (
              <LoaderDots />
            ) : (
              <div>
                <AlertLogo />
                <Copy>
                  <H2>{errorMsg}</H2>
                  <Link large href="/">
                    Click here to go back to the markets
                  </Link>
                </Copy>
              </div>
            )}
          </Main>
        </Container>
        {!hideNav && <Footer locale={'en'} />}
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { loading } = nextProps;
    const loadingData = loading;
    return {
      ...prevState,
      loadingData
    };
  }
}

export default ErrorPage;
