// @flow

import styled, { css } from 'styled-components';
import H2 from '@bitcoin-portal/bitcoincom-pkg-components/dist/H2/H2';

const Heading2 = styled(H2)`
  color: ${props => props.theme.foreground};
  ${props =>
    props.center &&
    css`
      width: fit-content;
      margin: 0 auto;
    `};
  ${props =>
    props.subtitle &&
    css`
      font-size: 32px;
      font-weight: 600;
    `};
`;
Heading2.displayName = 'H2';

export default Heading2;
