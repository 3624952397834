// @flow

import { SET_THEME, TOGGLE_DAY_NIGHT } from './constants';

export type State = 'light' | 'dark';

export const initialState: State = 'light';

const toggleDayNight = (state: State) => {
  if (state === 'light') return 'dark';
  return 'light';
};

// reducer
const theme = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case SET_THEME:
      return action.payload;
    case TOGGLE_DAY_NIGHT:
      return toggleDayNight(state);
    default:
      return state;
  }
};

export default theme;
