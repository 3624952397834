// @flow
// Define the color palette used across themes of the application
// Nameing use http://chir.ag/projects/name-that-color/#6195ED for consistency

import chroma from 'chroma-js';

const bgLight = chroma('#fbfcff');
const seaFoam = chroma('#0ac18e');
const sun = chroma('#fab915'); // aka bitcoin yellow
const mineShaft = chroma('#131720');
const forestgreen = chroma('forestgreen');
const indianred = chroma('indianred');

const midnight = chroma('#131720');
const codGray = chroma('#191919');
const malachite = chroma('#17D12F');
const vermillion = chroma('#F74909');
const blackHaze = chroma('#F2F4F3');

const palette = {
  bgLight,
  sun,
  seaFoam,
  mineShaft,
  forestgreen,
  indianred,
  codGray,
  malachite,
  midnight,
  vermillion,
  blackHaze
};

export default palette;
