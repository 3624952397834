// @flow
import { DateTime } from 'luxon';
import {
  FETCH_EXCHANGES,
  FETCH_EXCHANGES_SUCCESS,
  FETCH_EXCHANGES_FAIL
} from './constants';

export type State = {
  [symbol: string]: {
    fetching: number,
    lastUpdate: number,
    items: [
      {
        exchange: string,
        price_usd: number,
        volume: number
      }
    ]
  }
};

export const initialState: State = {};

const fetchExchangesBegin = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 1 } };
};

const fetchExchangesFail = (state: State, symbol: string): State => {
  return { ...state, [symbol]: { fetching: 0 } };
};

const fetchExchangesSuccess = (state: State, data: Object): State => {
  const symbol = data.symbol;
  const sortedExchanges = Object.entries(data.exchanges)
    .map(value => {
      return {
        exchange: value[0],
        totalVol: value[1].volume,
        items: []
      };
    })
    .sort((x, y) => y.totalVol - x.totalVol);

  const entry = {
    ...state[symbol],
    fetching: 2,
    lastUpdate: DateTime.utc(),
    items: sortedExchanges
  };
  return { ...state, [symbol]: entry };
};

const markets = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_EXCHANGES:
      return fetchExchangesBegin(state, action.payload);
    case FETCH_EXCHANGES_SUCCESS:
      return fetchExchangesSuccess(state, action.payload);
    case FETCH_EXCHANGES_FAIL:
      return fetchExchangesFail(state, action.payload);
    default:
      return state;
  }
};

export default markets;
