// @flow

import _chunk from 'lodash/chunk';

import {
  FETCH_MOVERS,
  FETCH_MOVERS_SUCCESS,
  FETCH_MOVERS_FAIL,
  PAGE_NEXT,
  PAGE_SIZE
} from './constants';

export type State = {
  currentPage: number,
  totalPages: number,
  gainers: [
    {
      name: string,
      symbol: string,
      price: number,
      volume: number,
      change24hr: number
    }
  ],
  losers: [
    {
      name: string,
      symbol: string,
      price: number,
      volume: number,
      change24hr: number
    }
  ]
};

export const initialState: State = {
  currentPage: 0,
  totalPages: 0,
  gainers: [],
  losers: []
};

const fetchMoversBegin = (state: State): State => {
  return { ...state };
};

const fetchMoversFail = (state: State): State => {
  return { ...state };
};

const fetchMoversSuccess = (state: State, data: Object): State => {
  const totalPages = _chunk(data.gainers, PAGE_SIZE).length - 1;
  return {
    ...state,
    gainers: data.gainers,
    losers: data.losers,
    totalPages
  };
};

const pageNext = (state: State): State => {
  if (state.totalPages === 0) return { ...state, currentPage: 0 };
  return {
    ...state,
    currentPage:
      state.totalPages > state.currentPage
        ? state.currentPage + 1
        : state.currentPage
  };
};

const movers = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case FETCH_MOVERS:
      return fetchMoversBegin(state);
    case FETCH_MOVERS_SUCCESS:
      return fetchMoversSuccess(state, action.payload);
    case FETCH_MOVERS_FAIL:
      return fetchMoversFail(state);
    case PAGE_NEXT:
      return pageNext(state);
    default:
      return state;
  }
};

export default movers;
