// @flow

export const FETCH_TOKEN_TICKER_ALL = 'tokens::FETCH_TOKEN_TICKER_ALL';
export const FETCH_TOKEN_TICKER_ALL_SUCCESS =
  'tokens::FETCH_TOKEN_TICKER_ALL_SUCCESS';
export const FETCH_TOKEN_TICKER_ALL_FAIL =
  'tokens::FETCH_TOKEN_TICKER_ALL_FAIL';

export const FETCH_TOKEN = 'tokens::FETCH_TOKEN';
export const FETCH_TOKEN_SUCCESS = 'tokens::FETCH_TOKEN_SUCCESS';
export const FETCH_TOKEN_FAIL = 'tokens::FETCH_TOKEN_FAIL';

export const FETCH_TOKEN_PLATFORM = 'tokens::FETCH_TOKEN_PLATFORM';
export const FETCH_TOKEN_PLATFORM_SUCCESS =
  'tokens::FETCH_TOKEN_PLATFORM_SUCCESS';
export const FETCH_TOKEN_PLATFORM_FAIL = 'tokens::FETCH_TOKEN_PLATFORM_FAIL';

export const SET_SORT_ORDER = 'tokens::SET_SORT_ORDER';

export const HIDDEN_TOKENS = ['BRST'];
export const PLATFORM_OPTIONS = [
  {
    label: 'Ardor',
    value: 'ardor'
  },
  {
    label: 'BitShares',
    value: 'bitshares'
  },
  {
    label: 'Counterparty',
    value: 'counterparty'
  },
  {
    label: 'Ethereum',
    value: 'ethereum'
  },
  {
    label: 'Nebulas',
    value: 'nebulas'
  },
  {
    label: 'NEO',
    value: 'neo'
  },
  {
    label: 'Omni',
    value: 'omni'
  },
  {
    label: 'Qtum',
    value: 'qtum'
  },
  {
    label: 'RSK Smart Bitcoin',
    value: 'rsk-smart-bitcoin'
  },
  {
    label: 'Stellar',
    value: 'stellar'
  },
  {
    label: 'Waves',
    value: 'waves'
  }
];
